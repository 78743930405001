import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Open the list`}</h4>
    <p>{`To see a list of all the tracks of the current map section click the
button on the left edge of the screen.`}</p>
    <Image src="features/listbutton.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`The list`}</h4>
    <p>{`The list show all tracks of the current map section, with the
most recently added one at the top.`}</p>
    <br />
    <p>{`The list shows the most important information about the activity such as
the `}<strong parentName="p">{`name`}</strong>{`, `}<strong parentName="p">{`category`}</strong>{`, `}<strong parentName="p">{`length`}</strong>{`, `}<strong parentName="p">{`place`}</strong>{`, `}<strong parentName="p">{`date`}</strong>{` added, user, and
the first lines of the `}<strong parentName="p">{`description`}</strong>{`. If a picture is available, this will
also be displayed.`}</p>
    <br />
    <p>{`In the dropdown menu you can change the sorting to "proximity to the map
center" and "trail length".`}</p>
    <br />
    <Link to="/features/showreviews" mdxType="Link">
  Read more about the "Reviews" and "Conditions" tabs here.
    </Link>
    <Image src="features/list.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Browse`}</h4>
    <p>{`Scroll down to see more activities.`}</p>
    <br />
    <p>{`The color of entry track entry shows the technical difficulty.`}</p>
    <br />
    <p>{`Green is easy, blue is intermediate, red is difficult, and black is very
difficult.`}</p>
    <br />
    <p>{`Click on an activity to open the activity page with a detailed description.`}</p>
    <Image src="features/listscroll.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/map" mdxType="Link">
  ← Map
    </Link>
    <Link className="float-right" to="/features/navigation" mdxType="Link">
  Show location →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      